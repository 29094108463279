interface AuthUrl {
  github: (redirectPath?: string) => string
  google: (redirectPath?: string) => string
}

export const getAuthUrl: AuthUrl = {
  github: (redirectPath?: string) => {
    return `${process.env.NEXT_PUBLIC_BACKEND_URL!}/user/login/github?state=${redirectPath}`
  },
  google: (redirectPath?: string) => {
    return `${process.env.NEXT_PUBLIC_BACKEND_URL!}/user/login/google?state=${redirectPath}`
  },
}
