import { cn } from '@repo/utils'

export const GnosisLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <path
        d="M11.8798 17.3839C12.4558 17.3839 13.0158 17.192 13.4718 16.84L9.8238 13.192C8.94381 14.328 9.15181 15.96 10.2878 16.84C10.7438 17.184 11.3038 17.3759 11.8798 17.3759V17.3839Z"
        fill="#3E6957"
      />
      <path
        d="M22.72 14.784C22.72 14.208 22.528 13.648 22.176 13.192L18.528 16.84C19.664 17.7199 21.296 17.5119 22.176 16.376C22.528 15.92 22.72 15.36 22.72 14.784Z"
        fill="#3E6957"
      />
      <path
        d="M24.5599 10.816L22.9439 12.432C24.2399 13.992 24.0399 16.304 22.4799 17.608C21.112 18.7519 19.136 18.7519 17.768 17.608L16 19.3759L14.232 17.608C12.6721 18.9039 10.3601 18.7039 9.05611 17.144C7.91213 15.776 7.91213 13.8 9.05611 12.432L8.23212 11.608L7.44813 10.816C6.49615 12.376 6.00015 14.168 6.00015 16C6.00015 21.5199 10.4801 25.9999 16 25.9999C21.52 25.9999 25.9999 21.5199 25.9999 16C26.0079 14.176 25.4959 12.376 24.5599 10.816Z"
        fill="#3E6957"
      />
      <path
        d="M23.2321 9.09604C19.4242 5.10409 13.0963 4.95209 9.10432 8.76004C8.98432 8.87204 8.87232 8.98404 8.76832 9.09604C8.52033 9.36003 8.28833 9.63203 8.06433 9.91203L16.0002 17.8479L23.9361 9.91203C23.7201 9.62403 23.4801 9.35203 23.2321 9.09604ZM16.0002 7.30406C18.3362 7.30406 20.5122 8.20805 22.1522 9.84803L16.0002 16L9.84831 9.84803C11.4803 8.20005 13.6643 7.30406 16.0002 7.30406Z"
        fill="#3E6957"
      />
    </svg>
  )
}
