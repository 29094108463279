import { toast as src } from 'react-toastify'

const sharedConf: any = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
}

export const toast = {
  error: (message: string) => {
    return src.error(message || 'Some thing Went Wrong', sharedConf)
  },
  success: (message: string) => {
    return src.success(message || 'Some thing Went Wrong', sharedConf)
  },
}
