import { cn } from '@repo/utils'

export const KavaLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <rect width="32" height="32" fill="#FF433E" />
      <path
        d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27Z"
        fill="#FF433E"
      />
      <path
        d="M13.0867 9.64922H10.8695V22.3594H13.0867V9.64922Z"
        fill="white"
      />
      <path
        d="M19.1539 22.3508L14.2899 16L19.1539 9.64922H21.9297L17.143 16L21.9297 22.3508H19.1539Z"
        fill="white"
      />
    </svg>
  )
}
