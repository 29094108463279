import { cn } from '@repo/utils'

export const OptimismLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <rect width="32" height="32" fill="#FF0420" />
      <g clip-path="url(#clip0_4_2461)">
        <path
          d="M11.6047 20.48C10.6397 20.48 9.84902 20.2477 9.23263 19.7831C8.62444 19.3102 8.32031 18.6382 8.32031 17.7671C8.32031 17.5846 8.3406 17.3606 8.38111 17.0951C8.48658 16.4978 8.6366 15.7802 8.83122 14.9422C9.38265 12.6608 10.8059 11.52 13.101 11.52C13.7254 11.52 14.285 11.6279 14.7796 11.8436C15.2744 12.051 15.6636 12.3662 15.9474 12.7893C16.2313 13.2042 16.3732 13.702 16.3732 14.2827C16.3732 14.4569 16.353 14.6768 16.3124 14.9422C16.1908 15.6806 16.0448 16.3982 15.8745 17.0951C15.5906 18.2317 15.1 19.0821 14.4026 19.6462C13.7051 20.2021 12.7725 20.48 11.6047 20.48ZM11.775 18.688C12.2292 18.688 12.6144 18.5511 12.9307 18.2773C13.255 18.0036 13.4862 17.5846 13.6241 17.0204C13.8106 16.2406 13.9525 15.5603 14.0498 14.9796C14.0822 14.8053 14.0984 14.627 14.0984 14.4444C14.0984 13.6895 13.7133 13.312 12.9428 13.312C12.4887 13.312 12.0994 13.4489 11.775 13.7227C11.4587 13.9964 11.2317 14.4154 11.0938 14.9796C10.9478 15.5354 10.8018 16.2157 10.6559 17.0204C10.6235 17.1864 10.6072 17.3606 10.6072 17.5431C10.6072 18.3064 10.9965 18.688 11.775 18.688Z"
          fill="white"
        />
        <path
          d="M16.9319 20.3556C16.8427 20.3556 16.7738 20.3265 16.7251 20.2684C16.6845 20.202 16.6724 20.1274 16.6886 20.0444L18.3673 11.9556C18.3835 11.8643 18.4281 11.7896 18.5011 11.7316C18.5741 11.6735 18.6511 11.6444 18.7322 11.6444H21.968C22.8682 11.6444 23.5899 11.8353 24.1332 12.2169C24.6847 12.5985 24.9604 13.1502 24.9604 13.872C24.9604 14.0794 24.9361 14.2951 24.8875 14.5191C24.6847 15.4732 24.2752 16.1784 23.6588 16.6347C23.0506 17.0909 22.2153 17.3191 21.153 17.3191H19.5107L18.9512 20.0444C18.935 20.1357 18.8904 20.2104 18.8173 20.2684C18.7444 20.3265 18.6673 20.3556 18.5862 20.3556H16.9319ZM21.2381 15.6018C21.5787 15.6018 21.8747 15.5064 22.1261 15.3156C22.3856 15.1247 22.5559 14.8509 22.6371 14.4942C22.6614 14.3532 22.6735 14.2287 22.6735 14.1209C22.6735 13.8803 22.6046 13.6978 22.4668 13.5733C22.3288 13.4406 22.0937 13.3742 21.7612 13.3742H20.3014L19.8392 15.6018H21.2381Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_2461">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
