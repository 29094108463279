import { cn } from '@repo/utils'

export const LineaLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <rect width="32" height="32" fill="black" />
      <g clip-path="url(#clip0_4_2480)">
        <mask
          id="mask0_4_2480"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="7"
          width="15"
          height="17"
        >
          <path d="M23.8506 7.92307H9V23.4355H23.8506V7.92307Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4_2480)">
          <path
            d="M21.3335 23.4351H9.00009V10.4398H11.822V20.9166H21.3335V23.4338V23.4351Z"
            fill="white"
          />
          <path
            d="M21.3327 12.9574C22.7229 12.9574 23.8499 11.8304 23.8499 10.4402C23.8499 9.05004 22.7229 7.92307 21.3327 7.92307C19.9426 7.92307 18.8156 9.05004 18.8156 10.4402C18.8156 11.8304 19.9426 12.9574 21.3327 12.9574Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4_2480">
          <rect
            width="15"
            height="16.1538"
            fill="white"
            transform="translate(9 7.92307)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
