import { cn } from '@repo/utils'

export const AvalancheLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <rect width="32" height="32" fill="#E84142" />
      <rect
        width="26.0213"
        height="26.0386"
        transform="translate(2.97873 2.9807)"
        fill="#E84142"
      />
      <path
        d="M25.1205 7.24933H7.08896V23.6487H25.1205V7.24933Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29 16.1457C29 23.245 23.245 29 16.1457 29C9.0465 29 3.29144 23.245 3.29144 16.1457C3.29144 9.04648 9.0465 3.29143 16.1457 3.29143C23.245 3.29143 29 9.04648 29 16.1457ZM12.5032 21.2611H10.0085C9.48433 21.2611 9.22539 21.2611 9.06751 21.1601C8.89699 21.0495 8.79278 20.8664 8.78015 20.6642C8.77067 20.478 8.90015 20.2505 9.15909 19.7958L15.3187 8.93867C15.5808 8.47762 15.7134 8.2471 15.8808 8.16185C16.0608 8.07027 16.2755 8.07027 16.4555 8.16185C16.6229 8.2471 16.7555 8.47762 17.0176 8.93867L18.2839 11.1491L18.2903 11.1604C18.5734 11.655 18.717 11.9058 18.7796 12.1691C18.8491 12.4564 18.8491 12.7596 18.7796 13.047C18.7165 13.3122 18.5744 13.5648 18.287 14.0669L15.0515 19.7864L15.0432 19.801C14.7582 20.2997 14.6138 20.5524 14.4137 20.7431C14.1958 20.9516 13.9337 21.1031 13.6463 21.1885C13.3842 21.2611 13.0905 21.2611 12.5032 21.2611ZM18.803 21.2611H22.3776C22.9049 21.2611 23.1702 21.2611 23.3282 21.157C23.4987 21.0464 23.606 20.86 23.6156 20.6581C23.6246 20.4778 23.498 20.2592 23.2498 19.8309C23.2412 19.8164 23.2327 19.8015 23.224 19.7864L21.4334 16.7234L21.4131 16.6889C21.1615 16.2634 21.0345 16.0486 20.8713 15.9655C20.6914 15.8739 20.4797 15.8739 20.2998 15.9655C20.1356 16.0508 20.003 16.275 19.7409 16.7265L17.9567 19.7896L17.9506 19.8001C17.6894 20.251 17.5589 20.4763 17.5683 20.6611C17.5809 20.8633 17.6852 21.0495 17.8557 21.1601C18.0104 21.2611 18.2757 21.2611 18.803 21.2611Z"
        fill="#E84142"
      />
    </svg>
  )
}
