import { cn } from '@repo/utils'

export const PolygonLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <rect width="32" height="32" fill="#6F41D8" />
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill="#6F41D8"
      />
      <path
        d="M19.819 13.5197C19.5422 13.3585 19.183 13.3585 18.8785 13.5197L16.7192 14.7603L15.253 15.5688L13.0937 16.8085C12.817 16.9705 12.4577 16.9705 12.1532 16.8085L10.4372 15.838C10.1605 15.6768 9.96698 15.3805 9.96698 15.0565V13.1425C9.96698 12.8192 10.1327 12.523 10.4372 12.361L12.1247 11.4175C12.4022 11.2555 12.7622 11.2555 13.0667 11.4175L14.7542 12.361C15.0317 12.523 15.2252 12.8192 15.2252 13.1425V14.383L16.6915 13.5468V12.307C16.6931 12.146 16.65 11.9877 16.567 11.8497C16.484 11.7117 16.3643 11.5995 16.2212 11.5255L13.0937 9.7465C12.817 9.5845 12.4577 9.5845 12.1532 9.7465L8.97023 11.5255C8.8272 11.5995 8.70751 11.7117 8.62449 11.8497C8.54146 11.9877 8.49836 12.146 8.49998 12.307V15.892C8.49998 16.216 8.66573 16.5122 8.97023 16.6743L12.1532 18.4532C12.43 18.6145 12.79 18.6145 13.0937 18.4532L15.253 17.2397L16.7192 16.4042L18.8785 15.1915C19.1552 15.0295 19.5145 15.0295 19.819 15.1915L21.5072 16.135C21.7847 16.2963 21.9775 16.5925 21.9775 16.9165V18.8305C21.9775 19.1538 21.8125 19.45 21.5072 19.612L19.8197 20.5825C19.5422 20.7445 19.1822 20.7445 18.8785 20.5825L17.1902 19.639C16.9127 19.477 16.7192 19.1807 16.7192 18.8575V17.617L15.253 18.4532V19.693C15.253 20.0162 15.4187 20.3132 15.7232 20.4745L18.9062 22.2535C19.183 22.4155 19.5422 22.4155 19.8467 22.2535L23.0297 20.4745C23.3065 20.3132 23.5 20.017 23.5 19.693V16.108C23.5016 15.947 23.4585 15.7887 23.3755 15.6507C23.2925 15.5127 23.1728 15.4005 23.0297 15.3265L19.8197 13.5197H19.819Z"
        fill="white"
      />
    </svg>
  )
}
