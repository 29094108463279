import { cn } from '@repo/utils'

export const EthereumLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <path
        d="M15.9989 5.71428L15.8574 6.18308V19.7854L15.9989 19.9231L22.4722 16.1909L15.9989 5.71428Z"
        fill="#343434"
      />
      <path
        d="M15.9973 5.71428L9.5238 16.1909L15.9973 19.9231V13.3209V5.71428Z"
        fill="#8C8C8C"
      />
      <path
        d="M15.9989 21.1184L15.9192 21.2133V26.0586L15.9989 26.2857L22.4762 17.3882L15.9989 21.1184Z"
        fill="#3C3C3B"
      />
      <path
        d="M15.9973 26.2857V21.1184L9.5238 17.3882L15.9973 26.2857Z"
        fill="#8C8C8C"
      />
      <path
        d="M15.9964 19.9228L22.4697 16.1906L15.9964 13.3207V19.9228Z"
        fill="#141414"
      />
      <path
        d="M9.5238 16.1906L15.9973 19.9228V13.3207L9.5238 16.1906Z"
        fill="#393939"
      />
    </svg>
  )
}
