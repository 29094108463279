import { toast } from '@repo/utils'

interface AddEthereumChainProps {
  window: any
  chainId: number
  rpcUrl: string
  explorerUrl: string
  name: string
}

export async function addEthereumChain({
  window,
  chainId,
  rpcUrl,
  explorerUrl,
  name,
}: AddEthereumChainProps) {
  try {
    const data = await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainName: `BuildBear ${name}`,
          chainId: `0x${chainId.toString(16)}`,
          rpcUrls: [rpcUrl],
          blockExplorerUrls: [explorerUrl],
          nativeCurrency: {
            name: 'Native Token',
            symbol: 'TOKEN',
            decimals: 18,
          },
        },
      ],
    })

    if (data === null) {
      toast.success('Successfully added.')
    } else {
      toast.error('Unable to add.')
    }
  } catch (e: any) {
    switch (e.code) {
      case 4902:
        toast.error('Network is not available, please add it.')
        break
      case 4001:
        toast.error('User rejected the request.')
        break
      default:
        toast.error('Unable to add network.')
    }
  }
}
