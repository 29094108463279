import * as React from 'react'

import { cn } from '@repo/utils'
import { cva, type VariantProps } from 'class-variance-authority'

const badgeVariants = cva(
  'inline-flex items-center rounded-md border border-gray-200 px-3 py-0.5 text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 dark:border-gray-800 dark:focus:ring-gray-300',
  {
    variants: {
      variant: {
        default: 'border-[transparent] bg-gray-900 text-white',
        brand: 'border-[transparent] bg-brand-500 text-white',
        secondary: 'border-[transparent] bg-brand-100 text-brand-500',
        destructive: 'border-red-100 bg-red-50 text-red-500',
        outline: 'border-brand-300 text-brand-500',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
