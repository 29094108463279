import { cn, getChainIcon, getChainName, getTestNetName } from '@repo/utils'

interface SetChainIdAndTokensProps {
  nodeId: string
  tokenData: Record<string, any>
  chains: any[]
}

export const setChainIdAndTokens = async ({
  nodeId,
  tokenData,
  chains,
}: SetChainIdAndTokensProps) => {
  let tokens: string[] = []
  let errResponse: string | null = null

  let chain = {
    id: null,
    name: null,
  }

  const rpcUrl = `${process.env.NEXT_PUBLIC_RPC_URL}/${nodeId}`

  try {
    const response = await fetch(rpcUrl)
    const data = await response.json()

    if (!response.ok) {
      throw new Error(data.error)
    }

    const chainId = data?.forkingDetails?.chainId?.toString()
    if (chainId) {
      chain.id = chainId
      const tokenDataForChainId = tokenData[chainId]
      if (tokenDataForChainId) {
        tokens = Object.keys(tokenDataForChainId)
      }
      chain.name = getChainName(chainId, chains)
    }
  } catch (error: any) {
    errResponse = error.message
  }

  return { chain, tokens, errResponse }
}
