import { cn } from '@repo/utils'

export const BinanceLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <path
        d="M20.2387 17.7701L22.7292 20.2619L15.989 27.0081L9.24967 20.2629L11.7403 17.7701L15.9899 22.0455L20.2387 17.7701ZM24.4893 13.5167L27 16.0085L24.5112 18.5004L22.0207 16.0085L24.4893 13.5167ZM15.9899 13.5167L18.4796 15.9865L15.989 18.4793L13.4993 16.0085L15.989 13.5176L15.9899 13.5167ZM7.48967 13.5167L9.95917 16.0085L7.4915 18.4784L5 16.0085L7.48967 13.5167ZM15.9908 5.00809L22.7302 11.7331L20.2396 14.2241L15.9899 9.97068L11.7412 14.2461L9.25058 11.7542L15.9899 5.009L15.9908 5.00809Z"
        fill="#F0B90B"
      />
    </svg>
  )
}
