import React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cn } from '@repo/utils'
import { cva, type VariantProps } from 'class-variance-authority'

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap gap-2 rounded-md cursor-pointer transition-colors font-semibold text-sm disabled:opacity-50 disabled:pointer-events-none select-none',
  {
    variants: {
      variant: {
        default: 'bg-gray-950 text-gray-50 hover:bg-gray-900/90',
        brand: 'bg-brand-500 text-white hover:bg-brand-600',
        secondary: 'bg-brand-100 text-gray-950 hover:bg-brand-200',
        outline: 'border border-gray-200/60 hover:border-gray-300',
        ghost: 'hover:bg-gray-100 bg-transparent text-gray-950',
        destructive: 'bg-red-600 text-white hover:bg-red-700',
      },
      size: {
        default: 'h-10 px-4',
        sm: 'h-7 px-4 text-sm',
        lg: 'h-12 px-4 text-base',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, type = 'button', asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={type}
        {...props}
      />
    )
  },
)

Button.displayName = 'Button'

export { Button }
