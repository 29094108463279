import { cn } from '@repo/utils'

export const ArbitrumLogo = ({ className, ...restProps }: SVGIconProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('size-6', className)}
      {...restProps}
    >
      <rect width="32" height="32" fill="white" />
      <path
        d="M17.7347 15.2993L18.9656 13.2105L22.2837 18.3783L22.2852 19.3701L22.2744 12.5454C22.2665 12.3785 22.1779 12.2259 22.0366 12.1358L16.063 8.69984C15.9233 8.63115 15.746 8.63197 15.6066 8.70201C15.5877 8.71149 15.5701 8.72171 15.5531 8.73295L15.5323 8.74605L9.73388 12.1061L9.7114 12.1165C9.68241 12.1297 9.65322 12.1467 9.62569 12.1663C9.51574 12.2451 9.44272 12.3617 9.4191 12.4924C9.41559 12.5122 9.41291 12.5324 9.41177 12.5528L9.42085 18.1143L12.5114 13.3241C12.9005 12.689 13.7483 12.4843 14.5354 12.4955L15.459 12.5198L10.0166 21.2479L10.6581 21.6172L16.1657 12.5287L18.6001 12.5198L13.1068 21.8377L15.396 23.1545L15.6695 23.3117C15.7852 23.3587 15.9216 23.3611 16.0382 23.3189L22.0958 19.8084L20.9377 20.4796L17.7347 15.2993ZM18.2043 22.0637L15.8922 18.4347L17.3036 16.0396L20.3402 20.8258L18.2043 22.0637Z"
        fill="#2D374B"
      />
      <path
        d="M15.8916 18.4348L18.2038 22.0637L20.3396 20.8259L17.3031 16.0397L15.8916 18.4348Z"
        fill="#28A0F0"
      />
      <path
        d="M22.2848 19.3702L22.2833 18.3784L18.9653 13.2105L17.7343 15.2993L20.9374 20.4796L22.0956 19.8085C22.2091 19.7163 22.2779 19.5809 22.2851 19.4348L22.2848 19.3702Z"
        fill="#28A0F0"
      />
      <path
        d="M8.38095 20.3055L10.0164 21.2479L15.4588 12.5199L14.5352 12.4955C13.7483 12.4844 12.9004 12.689 12.5113 13.3241L9.4207 18.1143L8.38095 19.7118V20.3055Z"
        fill="white"
      />
      <path
        d="M18.5999 12.5198L16.1655 12.5287L10.658 21.6172L12.5831 22.7255L13.1065 21.8377L18.5999 12.5198Z"
        fill="white"
      />
      <path
        d="M23.3109 12.5073C23.2906 11.9982 23.0149 11.5322 22.5831 11.2608L16.5312 7.78046C16.1041 7.56542 15.5705 7.56511 15.1426 7.78036C15.0921 7.80584 9.25723 11.1899 9.25723 11.1899C9.17647 11.2285 9.0987 11.2746 9.02558 11.327C8.64014 11.6033 8.40519 12.0326 8.38095 12.5042V19.7119L9.4207 18.1144L9.41163 12.5529C9.41287 12.5325 9.41544 12.5124 9.41895 12.4927C9.44247 12.3618 9.51559 12.2452 9.62554 12.1663C9.65308 12.1467 15.5874 8.71151 15.6063 8.70202C15.7459 8.63199 15.9231 8.63116 16.0627 8.69985L22.0364 12.1359C22.1777 12.2259 22.2663 12.3785 22.2741 12.5454V19.4347C22.2669 19.5807 22.2091 19.7161 22.0956 19.8083L20.9374 20.4795L20.3398 20.8258L18.204 22.0636L16.038 23.3188C15.9213 23.361 15.7849 23.3586 15.6692 23.3116L13.1065 21.8376L12.583 22.7255L14.8861 24.0514C14.9622 24.0947 15.0301 24.1331 15.0858 24.1645C15.172 24.2128 15.2308 24.2451 15.2515 24.2552C15.4152 24.3347 15.6507 24.381 15.8629 24.381C16.0575 24.381 16.2472 24.3453 16.4268 24.2749L22.7182 20.6315C23.0793 20.3517 23.2918 19.9297 23.3109 19.4727V12.5073Z"
        fill="#96BEDC"
      />
    </svg>
  )
}
