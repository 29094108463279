import * as React from 'react'

import { cn } from '@repo/utils'
import { CircleAlert, TriangleAlert } from 'lucide-react'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'error' | 'default' | 'warning'
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {
    return (
      <div className="relative inline-flex">
        <input
          type={type}
          className={cn(
            'flex h-10 w-full rounded-md border border-gray-200 bg-transparent px-3 py-1 font-sans text-sm text-gray-950 transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:border-gray-950 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950',
            'focus-visible:ring-offset-0 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300',
            'disabled:cursor-not-allowed disabled:border-gray-300/60 disabled:bg-gray-100 disabled:opacity-50',
            className,
            {
              'border-red-400 bg-red-50/20 focus-visible:border-red-400 focus-visible:ring-red-400':
                variant === 'error',
              'border-yellow-400 bg-yellow-50/20 focus-visible:border-yellow-400 focus-visible:ring-yellow-400':
                variant === 'warning',
            },
          )}
          ref={ref}
          {...props}
        />

        {variant === 'error' && (
          <div className="absolute inset-y-[1px] right-1 flex items-center px-2">
            <CircleAlert className="size-4 text-red-400" aria-label="Error" />
          </div>
        )}

        {variant === 'warning' && (
          <div className="absolute inset-y-[1px] right-1 flex items-center px-2">
            <TriangleAlert
              className="size-4 text-yellow-400"
              aria-label="Warning"
            />
          </div>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'

export { Input }
